import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Step,
  ShowNotification,
  FillTheDatabase,
  setPending,
  setNbrAction,
  setError,
  HideNotification,
} from "../FillTheDatabase";
import {
  ChildHandler,
  crypt,
  Db,
  HistoSyncHandler,
  ReservationHandler,
  SignupHandler,
  TokenHandler,
} from "../../helper";
import { setShowErrorSync } from "../showModal";

import { emptyChildModal, FetchChild, getChildGroup, getChildMealServices, getAutorisations, getVigilances } from "./Child";

import {
  getAbsence,
  getArrayPresta,
  getBuses,
  getHolidays,
  getPrestation,
  getSignup,
  getStops,
  LoadFromStorageFilter,
  GetMealTypes,
  setDate,
  setIdDate,
} from "./Pointage";
import { formatTimeWithSecond, howMuchDayBetween, selectDate, setDateToHourZero } from "../../helper/date";
import { GetPointages, GetEnd } from "../../helper/Api";
import { GetUser } from "../GetUser";
import { checkFilterSanity } from "../../helper/filterHandler";
import { getAllPrestations } from "../../helper/dbPrestation";
import { getLocationID } from "../../helper/useLocationId";

export const SynchroPicture = createAsyncThunk(
  "Synchro/SyncPicture",
  async (action, { dispatch }) => {
    dispatch(ShowNotification());
    return ChildHandler.SendPictureToServeur(
      new Db(crypt.getSessionDecryptedKey()),
      Step,
      dispatch
    );
  }
);

export const ResetPicture = createAsyncThunk(
  "Synchro/ResetPicture",
  async (action, { dispatch }) => {
    dispatch(ShowNotification());
    var database = new Db(crypt.getSessionDecryptedKey());
    ChildHandler.ClearChild(database);
    var signupId = (await SignupHandler.GetFirstSignup(database)).id;
    return Promise.all([
      ChildHandler.TableChildFiller(database, dispatch, Step, signupId),
    ]);
  }
);
const parseError = (method) => (error) => {
  return {
    success: false,
    error: true,
    method: method,
    err: {
      data: error.response.data,
      code: error.status,
      url: error.response.url,
    },
  };
};

const parseSuccess = (method) => (value) => {
  var msg = [];
  var success = true;
  Object.values(value.body.pointages).forEach((pointage) => {
    if (!pointage.success) {
      success = false;
      msg.push({
        resaId: pointage.temp_id,
        success: pointage.success,
        message: pointage.message,
      });
    }
  });
  return {
    success: success,
    msg: msg,
    url: value.url,
    method: method,
    status: value.status,
    ok: value.ok,
    data: value.data.toString(),
    req: value,
  };
};
const parseSuccessEnd = (method) => (value) => {
  var success = true;

  return {
    success: success,
    msg: ["Les données ont bien été transmises"],
    url: value.url,
    method: method,
    status: value.status,
    ok: value.ok,
    data: value.data.toString(),
    req: value,
  };
};
export const SynchroEnd = createAsyncThunk(
  "Synchro/SyncEnd",
  async (payload, { dispatch, rejectWithValue}) => {
    return GetEnd(TokenHandler.getToken()).then((client) => {
      var error = null;
      var synchroId = 0;
      if (payload.error !== undefined) {
        var errJson = JSON.parse(payload.error.err.data);
        error = errJson.message;
        synchroId = errJson.synchro_id;
      } else {
        synchroId = JSON.parse(payload.payload.data).synchro_id;
      }
      return client
        .end__sync(
          {},
          {
            requestBody: {
              synchroId: synchroId,
              error: error,
              device_id: localStorage.getItem('UuidKey'),
              device_name: localStorage.getItem('NamedDeviceKey'),
            },
          }
        )
        .then(parseSuccessEnd("POST"))
        .catch(parseError("Post"))
        .then((result) => {
          dispatch(
            Step({
              key: "EndSend",
              action: "La synchronisation est terminée.",
              size: 1,
              end: true,
            })
          );
          return {
            success: result.success,
            payload: { ...result, req: null },
            error: result.error ? result : undefined,
          };
        })
    })
})

export const SynchroReservation = createAsyncThunk(
  "Synchro/SyncReservation",
  async (action, { dispatch, rejectWithValue }) =>
    ReservationHandler.getReservationsTable(
      new Db(crypt.getSessionDecryptedKey())
    )
      .where("is_modified")
      .equals(1)
      .toArray()
      .then(async (Reservations) => {
        var resArray = [];
        var userAgent = navigator.userAgent;

        Reservations.forEach((resa) => {
          var res = {
            temp_id: resa.id,
            child_signup_id: resa.child_signup_id,
            date: resa.prestation_date,
            prestation_id: resa.prestation_id,
            is_present: resa.is_present === undefined ? null : resa.is_present,
            is_forfait: resa.is_forfait,
            date_id: resa.date_id === undefined ? null : resa.date_id,
          };
          if (!resa.is_add) res.reservation_id = resa.id;
          if (resa.time_update) {
            res.arrived_at =
              resa.time_update_a &&
              resa.arrived_at !== undefined &&
              resa.arrived_at !== null
                ? formatTimeWithSecond(resa.arrived_at)
                : undefined;
            res.departed_at =
              resa.time_update_d &&
              resa.departed_at !== undefined &&
              resa.departed_at !== null
                ? formatTimeWithSecond(resa.departed_at)
                : undefined;

            if (/[a-zA-Z]/.test(resa.arrived_at) || resa.arrived_at === '') res.arrived_at = undefined;
            if (/[a-zA-Z]/.test(resa.departed_at) || resa.departed_at === '') res.departed_at = undefined;
            if (resa.arrived_at === null) res.arrived_at = null;
            if (resa.departed_at === null) res.departed_at = null;
          }
          if (resa.absence !== null && resa.is_present === 0) {
            res.absence_type_id = resa.absence.absence_type_id;
          }
          resArray.push(res);
        });
        if (resArray.length === 0) {
          resArray = null;
        };
        return GetPointages(TokenHandler.getToken()).then((client) => {
          return client
            .pointages__sync(
              {},
              {
                requestBody: {
                  pointages: resArray,
                  device_name: localStorage.getItem('NamedDeviceKey'),
                  path_debug: window.location.pathname,
                  userAgent: userAgent,
                },
              }
            )
            .then(parseSuccess("Post"))
            .catch(parseError("Post"))
            .then((result) => {
              dispatch(
                Step({
                  key: "ResaSend",
                  action:
                    "Les modifications de réservations sont envoyées au serveur.",
                  size: 1,
                  end: true,
                })
              );
              return {
                success: result.success,
                payload: { ...result, req: null },
                error: result.error ? result : undefined,
              };
            });
        });
      })
);

export const ResetReservation = createAsyncThunk(
  "Synchro/ResetReservation",
  async (action, { dispatch, getState }) => {
    dispatch(ShowNotification());
    var db = new Db(crypt.getSessionDecryptedKey());
    ReservationHandler.ClearReservations(db);
    return ReservationHandler.TableReservationsFiller(db, dispatch, Step);
  }
);

export const getStat = createAsyncThunk(
  "Synchro/Stat",
  async (action, { dispatch }) => {
    var db = new Db(crypt.getSessionDecryptedKey());
    return initialStat(
      {},
      await ReservationHandler.getCountChildModified(db),
      await ReservationHandler.getCountChildAdded(db),
      await ChildHandler.GetCountPictureChanged(db)
    );
  }
);

export const GeneraleSync = createAsyncThunk(
  "Synchro/General",
  async (action = {}, { dispatch, getState }) => {
    dispatch(setNbrAction(2));
    dispatch(emptyChildModal());
    const { Auth, Pointage } = getState();

    var WipeDatabase = action.WipeDatabase || false;
    var wipeEverything = action.wipeEverything || false;
    var payload;
    return new Promise((resolve, reject) => {
      dispatch(GetUser());
      if (!action.sync) {
        resolve(
          Promise.all([
            dispatch(SynchroReservation()),
            dispatch(SynchroPicture()),
          ])
        );
        return;
      }
      resolve([{ payload: {} }]);
    })
      .then((res) => {
        payload = res[0]?.payload;

        if ((payload !== undefined && payload.success) || action.force)
          return dispatch(
            FillTheDatabase({
              WipeDatabase: WipeDatabase,
              wipeEverything: wipeEverything,
            })
          );
      })
      .then(() => {
        if (payload !== undefined) {
          HistoSyncHandler.InsertSync(
            new Db(crypt.getSessionDecryptedKey()),
            {
              status: payload.success || true,
              accountId: Auth.SessionData.id,
              why: !payload.success && {
                msg: payload?.payload?.msg,
                data: payload.error &&
                  (payload.error.response ?
                    payload.error.response.data :
                    (payload.error.err && payload.error.err.data)),
                code: payload.error &&
                  (payload.error.status ||
                    (payload.error.err && payload.error.err.code)),
                url: payload.error &&
                  (payload.error.response ?
                    payload.error.response.url :
                    (payload.error.err && payload.error.err.url)),
              },
              ResAllRequete: payload.payload,
            },
            { ...payload, success: undefined, error: undefined }
          );

          if (!payload.success && !action.sync) {
            dispatch(
              setShowErrorSync({
                show: true,
                status: false,
                why: payload.payload.msg || JSON.parse(payload.payload.err.data).message,
                accountId: Auth.SessionData.id,
              })
            );
            dispatch(setError(true));
            dispatch(HideNotification());
          }
        }
      })
      .catch((err) => {
        var error = {
          status: false,
          why: err.toString(),
          accountId: Auth.SessionData.id,
        };
        dispatch(setShowErrorSync({ ...error, show: true }));
        dispatch(setError(true));
        dispatch(HideNotification());
        HistoSyncHandler.InsertSync(
          new Db(crypt.getSessionDecryptedKey()),
          error,
          payload
        );
      })
      .then(() => {
        dispatch(GetLastDateSynchro())
      })
      .then(() => {
        if (action.after !== undefined) action.after();
        afterSyncData(
          dispatch,
          Pointage.date,
          {
            after: () => {
              dispatch(setPending(false));
            },
          },
          Pointage.momentID,
          payload,
          Auth.SessionData.id,
          Pointage.idDate,
          Pointage,
        );
      });
  }
);

export const afterSyncData = (dispatch, date, action, moment, payload, SessionDataId, idDate) => {
  dispatch(getArrayPresta());
  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/pointage"
  ) {
    Promise.all([
      dispatch(getAbsence()),
      dispatch(getSignup()),
      dispatch(getHolidays()),
      dispatch(getChildGroup()),
      dispatch(getChildMealServices()),
      dispatch(getAutorisations()),
      dispatch(getVigilances()),
      dispatch(getPrestation({date: date})),
      dispatch(FetchChild()),
      dispatch(setDate({ date: date, forceMoment: moment, dateId: idDate })),
      dispatch(getBuses()),
      dispatch(GetMealTypes()),
    ]).then(() => {
      dispatch(CheckFilterSanity());
      dispatch(setNearDate());
      if (action !== undefined && action.after !== undefined) {
        action.after();
        // dispatch(setSyncPending(false));
      }
    })
  } else if (action !== undefined && action.after !== undefined) {
    Promise.all([
      dispatch(getAbsence()),
      dispatch(getSignup()),
      dispatch(getHolidays()),
      dispatch(getChildGroup()),
      dispatch(getChildMealServices()),
      dispatch(getAutorisations()),
      dispatch(getVigilances()),
      dispatch(getPrestation({date: date})),
      dispatch(FetchChild()),
      dispatch(getBuses()),
      dispatch(GetMealTypes()),
    ]).then((res) => {
      dispatch(CheckFilterSanity());
      dispatch(setNearDate());
      action.after();
     // dispatch(setSyncPending(false));
    })
  }
  Promise.all([
    dispatch(SynchroEnd(payload)),
  ]).then((res) => {
    var error = null;
    if (res.length > 0 && res[0].meta.arg && res[0].meta.arg.error && res[0].meta.arg.error.err) {
      error = {
        show: true,
        status: false,
        why: JSON.parse(res[0].meta.arg.error.err.data).message,
        accountId: SessionDataId,
      };
    } else {
      if (res.length > 0 && res[0].payload?.error && res[0].payload.error.err) {
        error = {
          show: true,
          status: false,
          why: JSON.parse(res[0].payload.error.err.data).message,
          accountId: SessionDataId,
        };
      }
    }
    if (error !== null) {
      dispatch(setShowErrorSync({ ...error, show: true }));
      dispatch(setError(true));
      dispatch(HideNotification());
      HistoSyncHandler.InsertSync(
        new Db(crypt.getSessionDecryptedKey()),
        error,
        payload
      );
    }
  }).catch(err => {
    var error = {
      show: true,
      status: false,
      why: err.message,
      accountId: SessionDataId,
    };
    dispatch(setShowErrorSync({ ...error, show: true }));
    dispatch(setError(true));
    dispatch(HideNotification());
    HistoSyncHandler.InsertSync(
      new Db(crypt.getSessionDecryptedKey()),
      error,
      payload
    );
  })
};

export const setNearDate = createAsyncThunk(
  "Database/setNearDate",
  async (nothing, { getState, dispatch}) => {
    var { Pointage } = getState();

    if (getLocationID(document.location).includes(10)) {
      var presta = Pointage.Prestations.find(
        ({ id }) =>
          String(id) === String(document.location.pathname.split("/")[3])
      );

      if (presta !== undefined) {
        var selectDateRes = selectDate(presta);
        dispatch(
          setIdDate(
            (selectDateRes.idDate !== null)
              ? selectDateRes.idDate
              : (presta.possible_dates.length !== 0
                ? presta.possible_dates.findIndex(
                    (possible) =>
                      setDateToHourZero(possible.date).getTime() ===
                      setDateToHourZero(selectDateRes.date).getTime()
                  )
                : howMuchDayBetween(
                    presta.start_date,
                    setDateToHourZero(selectDateRes.date)
                  ) !== 0
                ? Math.round(
                    howMuchDayBetween(
                      presta.start_date,
                      setDateToHourZero(selectDateRes.date)
                    )
                  )
                : 0)
        ))
        dispatch(
          setDate({
            date: selectDateRes.date,
            pending: false,
            dateId:
              (selectDateRes.idDate !== null)
              ? selectDateRes.idDate
              : (presta.possible_dates.length !== 0
                ? presta.possible_dates.findIndex(
                    (possible) =>
                      setDateToHourZero(possible.date).getTime() ===
                      setDateToHourZero(selectDateRes.date).getTime()
                  )
                : howMuchDayBetween(
                    presta.start_date,
                    setDateToHourZero(selectDateRes.date)
                  ) !== 0
                ? Math.round(
                    howMuchDayBetween(
                      presta.start_date,
                      setDateToHourZero(selectDateRes.date)
                    )
                  )
                : 0),
              prestaDateId: selectDateRes.date.id,
          })
        );
      }
    }
  }
);

export const CheckFilterSanity = createAsyncThunk(
  "Database/CheckFilterSanity",
  async (nothing, { getState, dispatch }) => {
    var { Pointage, Child } = getState();
    var signup = Pointage.Signup[0];
    // Obtention de tous les arrêts des bus
    var stops = getStops(Pointage);
    getAllPrestations(new Db(crypt.getSessionDecryptedKey()))
      .then((allPresta) => checkFilterSanity(
          signup.levels,
          signup.schools,
          Child.childGroup,
          Pointage.Bus,
          allPresta,
          signup.teachers,
          stops,
          Child.childMealService
        )
      )
      .then(() => dispatch(LoadFromStorageFilter()));
  }
);

const initialStat = (state, nbrPointe, nbrAjoute, nbrPhoto) => {
  return {
    ...state,
    nbrPointe: nbrPointe,
    nbrAjoute: nbrAjoute,
    nbrPhoto: nbrPhoto,
  };
};

export const GetLastDateSynchro = createAsyncThunk(
  "synchro/getDate",
  async () => {
    if (
      crypt.getSessionDecryptedKey() !== null &&
      crypt.getSessionDecryptedKey() !== undefined
    )
      return HistoSyncHandler.GetLastSync(
        new Db(crypt.getSessionDecryptedKey())
      );
  }
);

export const SynchroSlice = createSlice({
  name: "Synchro",
  initialState: {
    Pending: false,
  },
  reducers: {
    resetStat: (state) => initialStat(state),
    setSyncPending: (state, { payload }) => {
      state.Pending = payload;
    },
    setSyncDuration: (state, { payload }) => {
      state.duration = payload;
    },
  },
  extraReducers: {
    [GeneraleSync.pending]: (state) => {
      state.Pending = true;
    },
    [GeneraleSync.rejected]: (state) => {
      state.Pending = false;
    },
    [GeneraleSync.fulfilled]: (state) => {
      state.Pending = false;
    },
    [getStat.pending]: (state) => {
      state.Pending = true;
    },
    [getStat.rejected]: (state) => {
      state.Pending = false;
    },
    [getStat.fulfilled]: (state, { payload }) => {
      state.Pending = false;
      state.stats = payload;
    },
    [GetLastDateSynchro.pending]: (state) => {
      state.Pending = true;
    },
    [GetLastDateSynchro.rejected]: (state) => {
      state.Pending = false;
    },
    [GetLastDateSynchro.fulfilled]: (state, { payload }) => {
      state.Pending = false;
      if (payload !== undefined) state.lastSync = payload.day;
    },
  },
});
export const { resetStat, setSyncPending, setSyncDuration } = SynchroSlice.actions;
export default SynchroSlice.reducer;
