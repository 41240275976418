import Dexie from "dexie";

/**
 * * Class Db permet la gestion de toute la partie Base de donnée
 */
export class Db {
  db;
  table;
  constructor(cryptKey) {
    this.db = new Dexie(process.env.REACT_APP_DBNAME);
    Dexie.debug = process.env.NODE_ENV === "development";
    this.table = {
      Child: "ChildrenTable",
      SignupYears: "SignupYearsTable",
      Contact: "ContactTable",
      Reservations: "ReservationsTable",
      ClosingDay: "ClosingDayTable",
      HistoSync: "HistoriqueDeSynchronisationTable",
      Absence: "AbsenceTable",
      Prestations: "PrestationsTable",
      Groups: "GroupsTable",
      Presence: "PresenceTable",
      Buses: "BusesTable",
      MealTypes: "MealTypesTable",
      MealServices: "MealServicesTable",
      Autorisations: "AutorisationsTable",
      Vigilances: "VigilancesTable",
    };
    if (cryptKey !== null) {
      this.db.version(2.7).stores({
        [this.table.Child]:
          "++id, first_name, last_name, alias, birth_date, PictureChange",
        [this.table.SignupYears]: "id++ , label , end_date, start_date",
        [this.table.Contact]: "id",
        [this.table.Reservations]:
          "id++, prestation_id, prestation_type, prestation_moment, child_id, is_modified, is_add, is_deleted, prestation_date, is_forfait",
        [this.table.ClosingDay]:
          "id++, start_date, end_date, isPHolidays, isSHolidays",
        [this.table.HistoSync]: "id++",
        [this.table.Absence]: "id",
        [this.table.Prestations]:
          "id, type, code, start_date, end_date, moment, *possible_days, secondary",
        [this.table.Groups]: "id, *services",
        [this.table.Buses]: "id",
        [this.table.MealTypes]: "id, name",
        [this.table.MealServices]: "id, name",
        [this.table.Autorisations]: "id, name",
        [this.table.Vigilances]: "id, name, picto, color",
      });

    }
  }
  /**
   * * Retourne l'objet DB
   */
  getDB = () => this.db;
  /**
   * *Retourne les nom de Table
   */
  getTableName = () => this.table;
  /**
   * * Permet d'insérer ou mettre a jour une donnée
   * @param {integer} Element id
   * @param {Object} data
   * @param {String} Ou mettre les donnée
   */
  insertOrUpdate = async (id, data, table) => {
    if (data.id && id === null) {
      data.Pid = data.id;
      data.id = undefined;
    }
    return this.db.table(table).put(data, id);
  };
  insertLotsOfData = async (data, table) => {
    const bulkSize = 300;
    for(let i = 0; i < Math.ceil(data.length / bulkSize); i++) {
      const chunk = data.slice(i * bulkSize, (i + 1) * bulkSize);
      const key = "page_" + i + "_" + table;
      this.db.table(table).bulkPut(chunk, key);
    }
    return
  };

  /**
   * * Permet d'insérer ou mettre a jour une donnée
   * @param {integer} Element id
   * @param {Object} data
   * @param {String} Ou mettre les donnée
   */
  insertIfNotDouble = (id, data, table) =>
    this.db
      .table(table)
      .add({ ...data, id: id })
      .catch(() => true);

  /**
   * * Permet de vider une table
   * @param {String} c
   */
  clearTable = (tableName) => this.db.table(tableName).clear();
  /**
   * * Permet de vider la bdd
   */
  wipeDatabase = () => this.db.delete();
}
