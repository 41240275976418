import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { hide } from '../../../actions/Database/Child'
import ModalchildBody from './modalChildBody'
import ModalChildHeaderInfo from './modalChildHeaderInfo'
import { dateIsBetweenOrEqualDate, dateStringToDate } from '../../../helper/date'
import Modal from '../Modal'
import IcomoonSVG from '../../IcomoonSVG'
import 'react-image-crop/dist/ReactCrop.css'
import { setCropData } from '../../../actions/showModal'
import { getVigilancesForChild } from '../../../helper/vigilances'

const ModalChild = ({ Child, dispatch, Signup }) => {
  const [newphoto, setNewphoto] = useState(null)
  useEffect(() => {
    if (Child.childModal.new_photo !== undefined) {
      setNewphoto(Child.childModal.new_photo)
    }
  }, [Child.childModal.new_photo])
  if (Child == null || Child.childModal == null) return <div></div>
  var child = Child.childModal

  const vigilances = getVigilancesForChild(Child.vigilancesList, child)

  if (Object.keys(child).length === 0 && child.constructor === Object)
    return <div className='DivThatContainsTheChildModal'></div>
  var signup = child.signups.find(
    (value) =>
      value.year_id ===
      Signup.find((signupG) =>
        dateIsBetweenOrEqualDate(
          new Date(Child.dateChildModal),
          dateStringToDate(signupG.start_date),
          dateStringToDate(signupG.end_date)
        )
      ).id
  )
  if (signup === undefined || child === undefined) return <div></div>
  const handleChangePicture = (event) => {
    // Ouverture modale pour validation de l'image
    if (event.target.files.length > 0) {
      dispatch(
        setCropData({ childId: child.id, picture: URL.createObjectURL(event.target.files[0]) })
      )
    }
  }

  return (
    <Modal
      show={Child.showModal}
      hide={() => dispatch(hide())}
      label=''
      size='xl'
      ComponentHeader={() => (
        <div className='grid'>
          <div className='grid__row'>
            <div className='grid__col2--small'>
              {child.has_photo || newphoto !== null ? (
                <img className='modal__childpicture' src={newphoto || child.photo} alt='' />
              ) : (
                <div className='modal__childnopicture'>
                  <svg className='icon icon__no_photography' focusable='false'>
                    <use
                      href={process.env.PUBLIC_URL + '/picto/symbol-defs.svg#icon__no_photography'}
                    ></use>
                  </svg>
                </div>
              )}

              <input
                type='file'
                accept='image/*'
                id='ChildModalChangePicture'
                hidden
                onChange={handleChangePicture}
              />
              <label className='modal__changephoto' htmlFor='ChildModalChangePicture'>
                <IcomoonSVG icon='camera' />
                Modifier la photo de l'enfant
              </label>
            </div>
            <div className='grid__col10--small'>
              <ModalChildHeaderInfo child={child} signup={signup} vigilances={vigilances} />
            </div>
          </div>
        </div>
      )}
    >
      <ModalchildBody child={child} signup={signup} />
    </Modal>
  )
}

export default connect(({ Child, Pointage, ShowModal }) => ({
  Child: {
    showModal: Child.showModal && !ShowModal.crop.show,
    childModal: Child.childModal,
    dateChildModal: Child.dateChildModal,
    vigilancesList: Child.vigilances,
  },
  Signup: Pointage.Signup,
}))(
  React.memo(ModalChild, (oldProps, newProps) => {
    if (oldProps.Child.showModal !== newProps.Child.showModal || newProps.Child.showModal)
      return false
    return true
  })
)
