import React from "react";
import { connect } from "react-redux";
import { ChildHandler } from "../../../helper";
import { prestationTypeParser, serviceTypeParser } from "../../../helper/user";
import IcomoonSVG from "../../IcomoonSVG";

const ModalChildBodyGeneral = ({ child, signup, Child, Pointage, Autorisations }) => {
  return (
    <div className="grid">
      <div className="grid__row">
        <div className="grid__col6">
          <div className="child__autorisations">
            {/** Ancienne version des autorisations (allow_*) **/}
            {signup.autorisations === null ?
              <>
                <p
                  className={
                    signup.allow_photos ? "color--success" : "color--error"
                  }
                >
                  Prises de vue autorisées :{" "}
                  <strong>{signup.allow_photos ? "Oui" : "Non"}</strong>
                  <IcomoonSVG icon={signup.allow_photos ? "check" : "cross"} />
                </p>

                <p
                  className={
                    signup.allow_emergency ? "color--success" : "color--error"
                  }
                >
                  Soins d'urgence autorisés :{" "}
                  <strong>{signup.allow_emergency ? "Oui" : "Non"}</strong>
                  <IcomoonSVG icon={signup.allow_emergency ? "check" : "cross"} />
                </p>

                <p
                  className={
                    signup.allow_self_leave ? "color--success" : "color--error"
                  }
                >
                  Autorisé à rentrer seul :{" "}
                  <strong>{signup.allow_self_leave ? "Oui" : "Non"}</strong>
                  <IcomoonSVG icon={signup.allow_self_leave ? "check" : "cross"} />
                </p>

                <p
                  className={
                    signup.allow_transport ? "color--success" : "color--error"
                  }
                >
                  Transport autorisé :{" "}
                  <strong>{signup.allow_transport ? "Oui" : "Non"}</strong>
                  <IcomoonSVG icon={signup.allow_transport ? "check" : "cross"} />
                </p>
              </> :
              <>
                {/** Nouvelle version des autorisations, avec un tableau qui liste toutes les autorisations **/}
                {signup.autorisations.length > 0 ?
                  signup.autorisations.map(autorisation => (
                    Autorisations.some(item => item.id === autorisation.id) ?
                    <p
                      className={
                        autorisation.allowed ? "color--success" : "color--error"
                      }
                      key={"autorisation-" + Autorisations.find(item => item.id === autorisation.id).name}
                    >
                      {Autorisations.find(item => item.id === autorisation.id).name} :{" "}
                      <strong>{autorisation.allowed ? "Oui" : "Non"}</strong>
                      <IcomoonSVG icon={autorisation.allowed ? "check" : "cross"} />
                    </p> : ""
                  ))
                : <p>Aucune autorisation n'a été effectuée</p>}
              </>
            }
          </div>
        </div>
        <div className="grid__col6">
          {(child.structure_notes ||
            child.family.structure_notes ||
            signup.split_parents ||
            signup.affectations.some(affect => affect.group_id !== null) ||
            signup.affectations.some(affect => affect.mealservice_id !== null) ||
            signup.regime_infos) && (
            <dl>
              {signup.affectations.some(affect => affect.group_id !== null) &&
                  signup.affectations
                    .filter(affect =>
                            affect.group_id !== null &&
                            // Détection si le type du service est lié au type de prestation choisi
                            Pointage.prestationsType.filter(type =>
                              prestationTypeParser()[type] === serviceTypeParser()[affect.type])
                                                    .length > 0)
                    .map(affect => (
                      <div className="child__info">
                        <dt>Groupes</dt>
                        <dd>{Child.childGroup.find(group => group.id === affect.group_id).name}</dd>
                      </div>
                    ))}
              {signup.affectations.some(affect => affect.mealservice_id !== null) &&
                Pointage.prestationsType.includes(5) &&
                <div className="child__info">
                  <dt>Services</dt>
                  {signup.affectations
                    .filter(affect => affect.mealservice_id !== null)
                    .map(affect => (
                      <dd>{Child.childMealService.find(mealservice => mealservice.id === affect.mealservice_id).name}</dd>
                    ))}
                </div>
              }

              {(child.structure_notes || child.family.structure_notes) && (
                <div className="child__info">
                  <dt>Note interne</dt>
                  {child.structure_notes && (
                    <dd><strong>Enfant :</strong><br /> <div dangerouslySetInnerHTML={{ __html: child.structure_notes.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div></dd>
                  )}
                  {child.family.structure_notes && (
                    <dd><strong>Famille :</strong><br /> <div dangerouslySetInnerHTML={{ __html: child.family.structure_notes.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div></dd>
                  )}
                </div>
              )}

              {signup.split_parents && (
                <div className="child__info">
                  <dt>Mode garde de l'enfant</dt>
                  <dd>
                    {ChildHandler.GetStringGuardMode()[signup.guard_mode]}
                  </dd>
                </div>
              )}

              {signup.regime_infos && (
                <div className="child__info">
                  <dt>Régime spécial</dt>
                  <dd>{signup.regime_infos}</dd>
                </div>
              )}
            </dl>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(({ Pointage, Child }) => ({
  Child: Child,
  Pointage: Pointage,
  Autorisations: Child.autorisations,
}))(ModalChildBodyGeneral);
