export const dateStringToDate = (date) => {
  var dateParts = date.split("-");
  return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
};

export const formatTime = (time) => {
  var timeSplit = time.split(":");
  return `${formatWithLeadingZero(timeSplit[0])}:${formatWithLeadingZero(
    timeSplit[1]
  )}`;
};
export const formatTimeDisplay = (time) => {
  var timeSplit = time.split(":");
  return `${formatWithLeadingZero(timeSplit[0])}h${formatWithLeadingZero(
    timeSplit[1]
  )}`;
};
export const formatTimeWithSecond = (time) => `${formatTime(time)}:00`;

export const formatDate = (date) =>
  `${date.getFullYear()}-${formatWithLeadingZero(
    date.getMonth() + 1
  )}-${formatWithLeadingZero(date.getDate())}`;

export const setDateToHourZero = (date) =>
  new Date(new Date(date).setHours(0, 0, 0, 0));

export const dateIsTheSame = (date1, date2) =>
  setDateToHourZero(date1) === setDateToHourZero(date2);

export const dateIsBetweenDate = (date, dateBeginning, dateEnd) =>
  setDateToHourZero(date) > setDateToHourZero(dateBeginning) &&
  setDateToHourZero(date) < setDateToHourZero(dateEnd);

export const dateIsBetweenOrEqualDate = (date, dateBeginning, dateEnd) =>
  setDateToHourZero(date) >= setDateToHourZero(dateBeginning) &&
  setDateToHourZero(date) <= setDateToHourZero(dateEnd);

export const dateIsGreeterThan = (dateToTest, dateInitial) =>
  setDateToHourZero(dateToTest) >= setDateToHourZero(dateInitial);

export const howMuchDaySince = (since) =>
  (new Date().getTime() - since.getTime()) / (1000 * 3600 * 24);

export const formatWithLeadingZero = (number) => ("0" + number).slice(-2);

export const incrementDate = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const timeIsBetweenOrEqualTime = (time, timeBeginning, timeEnd) =>
  timeIsLowerOrEqual(time, timeEnd) && timeIsLowerOrEqual(timeBeginning, time);

export const timeHasToBeBetween = (time, timeBeginning, timeEnd) => {
  if (timeIsLowerOrEqual(time, timeBeginning)) return timeBeginning;
  if (timeIsLowerOrEqual(timeEnd, time)) return timeEnd;
  return time;
};

export const checkTime = (time) =>
  time !== undefined && time !== null && time.split(":").length >= 2;

export const timeIsLower = (time1, time2) => {
  if (time1.split(":")[0] === time2.split(":")[0])
    return time1.split(":")[1] < time2.split(":")[1];
  if (time1.split(":")[0] < time2.split(":")[0]) return true;
  return false;
};
export const timeIsLowerOrEqual = (time1, time2) => {
  if (time1 === time2) return true;
  return timeIsLower(time1, time2);
};

export const reduceTime =
  (bigger = false) =>
  (prestaTreated, prestaToTreat) => {
    if (timeIsLower(prestaTreated[0], prestaToTreat[0]))
      return bigger ? prestaToTreat : prestaTreated;
    return bigger ? prestaTreated : prestaToTreat;
  };

export const equalOne = (prestaTreated, prestaToTreat) => {
  if (timeIsLowerOrEqual(prestaTreated[0], prestaToTreat[0])) return prestaToTreat;
  return prestaTreated;
};

export const lowerOne = (prestaTreated, prestaToTreat) => {
  if (timeIsLower(prestaTreated[0], prestaToTreat[0])) return prestaToTreat;
  return prestaTreated;
};

export const upperOne = (prestaTreated, prestaToTreat) => {
  if (timeIsLower(prestaTreated[0], prestaToTreat[0])) return prestaTreated;
  return prestaToTreat;
};

export const lowerTime =
  (startTime = false) =>
  (prestaTreated, prestaToTreat) => {
    if (
      timeIsLower(
        startTime ? prestaToTreat.start_time : prestaToTreat.end_time,
        startTime ? prestaTreated.start_time : prestaTreated.end_time
      )
    )
      return startTime ? prestaToTreat : prestaTreated;
    return startTime ? prestaTreated : prestaToTreat;
  };

export const howMuchMinute = (time) =>
  Number(time.split(":")[0]) * 60 + Number(time.split(":")[1]);

export const ordoTime = (ordonnancement, prestaToTreat) => {
  if (ordonnancement[prestaToTreat.start_time] === undefined) {
    ordonnancement = { ...ordonnancement, [prestaToTreat.start_time]: {} };
  }
  if (
    ordonnancement[prestaToTreat.start_time][prestaToTreat.end_time] ===
    undefined
  ) {
    ordonnancement[prestaToTreat.start_time] = {
      ...ordonnancement[prestaToTreat.start_time],
      [prestaToTreat.end_time]: [],
    };
  }
  ordonnancement[prestaToTreat.start_time][prestaToTreat.end_time].push(
    prestaToTreat
  );
  return ordonnancement;
};

export const checkIfDateIsInAllowed = (Auth, Synchro) => (date) =>
  dateIsBetweenOrEqualDate(
    date,
    new Date(
      formatDate(
        incrementDate(
          Synchro.lastSync ? new Date(Synchro.lastSync) : new Date(),
          0 - Auth.SessionData.RootPeriodBefore
        )
      )
    ),
    new Date(
      formatDate(
        incrementDate(
          Synchro.lastSync ? new Date(Synchro.lastSync) : new Date(),
          0 + Auth.SessionData.RootPeriodAfter
        )
      )
    )
  );

export const transformInputTime = (input) => `${input}`;

export const isBetweenNumber = (min, max, number) => {
  var retour = false;
  if (min && max) {
    retour = number >= min && number <= max;
  } else if (min) {
    retour = number >= min;
  } else if (max) {
    retour = number <= max;
  }
  return retour;
}

export const dateToHourString = (date) =>
  `${formatWithLeadingZero(date.getHours())}:${formatWithLeadingZero(
    date.getMinutes()
  )}`;

export const howMuchDayBetween = (firstDate, SecondDate) =>
  (setDateToHourZero(new Date(SecondDate)).getTime() -
    setDateToHourZero(new Date(firstDate)).getTime()) /
  (1000 * 3600 * 24);

export const substractHours = (numOfHours, date = new Date()) =>
    date.setHours(date.getHours() - numOfHours);

export const selectDate = (presta) => {
  var retour = dateIsBetweenOrEqualDate(
    new Date(),
    presta.start_date,
    presta.end_date
  )
    ? [true, new Date()]
    : [false, new Date(presta.start_date)];

  if (presta.possible_dates.length !== 0 && retour[0]) {
    // var retourReduce = presta.possible_dates.reduce(
    //   ({ ready, dateRetour }, date) => {
    //     if (ready) return { ready, dateRetour };
    //     if (
    //       setDateToHourZero(date.date).getTime() ===
    //       setDateToHourZero(new Date()).getTime()
    //     ) {
    //       return { dateRetour: setDateToHourZero(date.date), ready: true };
    //     }
    //     return { ready, dateRetour };
    //   },
    //   { ready: false, dateRetour: retour[1] }
    // );
    // return retourReduce.ready ? retourReduce.dateRetour : presta.start_date;

    var now = new Date();
    var dateChoisi = presta.possible_dates
      .filter(date => {
        var res = true;
        res = setDateToHourZero(date.date).getTime() <= setDateToHourZero(now).getTime();
        // if (res && setDateToHourZero(date.date).getTime() === setDateToHourZero(now).getTime()) {
        //   var start_time = date.start_time ? new Date(substractHours(2, new Date(date.start_time))).toLocaleTimeString() : presta.start_time;
        //   res = start_time <= now.toLocaleTimeString();
        // }
        return res;
      });

    if (dateChoisi === undefined || dateChoisi.length === 0) {
      var temp = [...presta.possible_dates];
      dateChoisi = temp
        .filter(date => {
          return setDateToHourZero(date.date).getTime() < setDateToHourZero(new Date()).getTime()})
        .sort((a, b) => {return a.date < b.date ? -1 : 1});
    }
    retour = [true, dateChoisi[dateChoisi.length - 1]];
    return {
      date: retour[1],
      idDate: dateChoisi.length - 1,
    };
  }
  return {
    date: retour[1],
    idDate: null,
  };
};

export const DateHandler = {
  DateStringToDate: dateStringToDate,
  DateIsBetweenDate: dateIsBetweenDate,
  HowMuchDaySince: howMuchDaySince,
  FormatWithLeadingZero: formatWithLeadingZero,
  FormatDate: formatDate,
  IndentDate: incrementDate,
  DateIsTheSame: dateIsTheSame,
  DateIsBetweenOrEqualDate: dateIsBetweenOrEqualDate,
  dateIsGreeterThan,
  howMuchDayBetween,
};
